













import { Component, Vue } from 'vue-property-decorator';
import CarsService, { CarsServiceS } from '@/modules/cars/cars.service';
import moment from 'moment';
import { Inject } from 'inversify-props';

@Component
export default class MedianComparisonTooltip extends Vue {}
