







import { Component, Vue } from 'vue-property-decorator';
import CarPopup from '@/modules/cars/components/car-popup.vue';

@Component({
    components: { CarPopup },
})
export default class DayRateModalPage extends Vue {
}
